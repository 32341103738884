<template>
  <div class="reports m-2 p-3 pb-5">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="6" order="1" order-md="2" class="p-0 m-0 text-md-right align-middle">
          <ul class="list-unstyled list-inline larkin-breadcrumb pt-3 mb-0">
            <li class="list-inline-item">
              <b-link :to="{name: 'reports'}" class="breadcrumb-link"><span class="icon icon-arrow-left"></span> <span> Back</span></b-link>
            </li>
            <li class="list-inline-item">|</li>
            <li class="list-inline-item">Select Your Report</li>
          </ul>
        </b-col>
        <b-col cols="12" md="6" order="2" order-md="1" class="p-0 m-0 report-name">
          <h3><i class="icon icon-file-spreadsheet2 text-primary mr-2"></i> <span class="position-relative">{{report.name}}</span></h3>
        </b-col>
      </b-row>
    </b-container>

    <b-card no-body>
      <b-tabs card>
        <div class="float-right position-relative download-links">
          <span v-if="report.id">
            <b-link @click="download('pdf')"><img src="/img/icons/pdf.png" class="mr-1" />PDF</b-link>
            <b-link @click="download('csv')"><img src="/img/icons/csv.png" class="mr-1"/>CSV</b-link>
          </span>
        </div>

        <b-tab title="Last Year" @click="selectDateRange('lastYear')"></b-tab>
        <b-tab title="This Year" @click="selectDateRange('thisYear')"></b-tab>
        <b-tab title="Last Month" @click="selectDateRange('lastMonth')"></b-tab>
        <b-tab title="This Month" @click="selectDateRange('thisMonth')"></b-tab>
        <b-tab title-item-class="custom-tab" active>
          <template slot="title">
            <span class="custom-tab-title">Custom</span>
            <ul class="list-inline custom-inline-form">
              <li class="list-inline-item">Custom:</li>
              <li class="list-inline-item li-inline-date-input">
                <b-form-input v-model="input.startDate" type="date" class="inline-date-input"></b-form-input>
              </li>
              <li class="list-inline-item">
                <v-date-picker v-model='datePicker.startDate'>
                  <button class="p-0 m-0">
                    <img src="/img/icons/calendar.png" />
                  </button>
                </v-date-picker>
              </li>
              <li class="list-inline-item">-</li>
              <li class="list-inline-item li-inline-date-input">
                <b-form-input v-model="input.endDate" type="date" class="inline-date-input"></b-form-input>
              </li>
              <li class="list-inline-item">
                <v-date-picker v-model='datePicker.endDate'>
                  <button class="p-0 m-0">
                    <img src="/img/icons/calendar.png" />
                  </button>
                </v-date-picker>
              </li>
              <li class="list-inline-item">
                <b-button class="custom-go-button" size="sm" variant="light" @click="getCustomReport">GO</b-button>
              </li>

            </ul>
          </template>
        </b-tab>
        <div class="m-3 report-results">
          <div v-if="reportLoading" class="text-secondary">Loading Report Data... <img alt="spinner" src="/img/spinner.gif" style="width:50px;"/></div>

          <div v-for="error in errors" v-bind:key="error">
            {{error.message}}
          </div>

          <!-- Desktop only: setting table to responsive allows for horizontal scrolling, but it wont stack on a small viewport -->
          <b-table v-if="report.items && !reportLoading" responsive sticky-header striped borderless :items="report.items" :fields="report.fields" class="mb-5 d-none d-md-block"></b-table>

          <!-- Tiny Viewport Only: We want the table to stack if viewed on a mobile device -->
          <b-table v-if="report.items && !reportLoading" stacked="md" sticky-header striped borderless :items="report.items" :fields="report.fields" class="mb-5 d-md-none"></b-table>

          <p v-if="!report.items && !reportLoading" class="text-secondary">No results for the date range selected, please try a different custom date range or one of our pre-selected intervals.</p>
        </div>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import DateMixin from '@/mixins/dateMixin'
  import FileMixin from '@/mixins/fileMixin'
  import camelcase from 'camelcase'
  import get from 'just-safe-get'


  const now = new Date();
  const dates = {
    today: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
    firstOfThisMonth: new Date(now.getFullYear(), now.getMonth(), 1),
    firstOfThisYear: new Date(now.getFullYear(), 0, 1),
    firstOfLastYear: new Date(now.getFullYear() - 1, 0, 1),
    endOfLastYear: new Date(now.getFullYear() - 1, 11, 31),
    firstOfLastMonth: new Date(now.getMonth() == 0 ? now.getFullYear() - 1 : now.getFullYear(), now.getMonth() == 0 ? 11 : now.getMonth() - 1, 1),
    endOfLastMonth: new Date(now.getFullYear(), now.getMonth(), 0)
  };

  export default {
    name: 'payment',
    mixins: [FormMixin, DateMixin, FileMixin],
    props: ['reportId'],
    data: function() {
      return {
        report: {},
        input: {
          view: 'custom',
          startDate: this.formatToIsoDate(dates.firstOfThisMonth),
          endDate: this.formatToIsoDate(dates.today)
        },
        datePicker: {},

        postParams: {
          pageNumber: 1
        },

        reportLoading: false,

        errors: []
      }
    },

    methods: {
      selectDateRange: function(viewName) {
        this.report = {};
        this.input.view = viewName;

        switch (viewName) {
          case 'lastYear': this.getReport(dates.firstOfLastYear, dates.endOfLastYear);
            break;
          case 'thisYear': this.getReport(dates.firstOfThisYear, dates.today);
            break;
          case 'thisMonth': this.getReport(dates.firstOfThisMonth, dates.today);
            break;
          case 'lastMonth': this.getReport(dates.firstOfLastMonth, dates.endOfLastMonth);
        }
      },

      setDates: function(startDate, endDate) {
        this.postParams.reportStartDate = this.formatDate(startDate);
        this.postParams.reportEndDate = this.formatDate(endDate);
        this.datePicker = {
          startDate : new Date(startDate),
          endDate : new Date(endDate)
        };
      },

      getCustomReport: function() {
        this.getReport(this.input.startDate, this.input.endDate)
      },

      handleError: function(e) {
        let error = get(e, 'response.data');

        if (error) {
          this.report.items = [];
          this.report.fields = [];
          this.reportLoading = false;
          this.errors.push(error)
        }
      },

      getReport: function(startDate, endDate) {
        this.errors = [];
        this.reportLoading = true;
        this.setDates(startDate, endDate);

        this.submit('post', ApiProperties.api.report, { pathVars: { reportId: this.reportId }}, this.postParams, response => {
          this.report = this.initReport(response.data);
          this.reportLoading = false;
        }, this.handleError);
      },

      initReport: function(responseData) {
        let report = {};
        report.id = responseData.id;
        report.name = responseData.name;
        report.fields = this.initFields(responseData.columns);

        if (get(responseData, 'data.rows')!==undefined) {
          report.items = this.initItems(responseData.data.rows, report.fields);
        }

        return report;
      },

      initFields: function(columns) {
        let fields = [];

        if (columns) {
          for (let i=0; i<columns.length; i++) {
            let column = columns[i];

            fields.push({
              key: camelcase(column.name),
              label: column.name,
              sortable: column.sortable,
              formatter: (column.dataType==="DATE") ? this.formatDate : function(val) { return val; }
            });
          }
        }

        return fields;
      },

      initItems: function(rows, fields) {
        let items = [];

        if (rows && fields) {
          for (let i=0; i<rows.length; i++) {
            let rowAsHash = {};
            let row = rows[i];

            if (row.length === fields.length) {
              for (let j=0; j<row.length; j++) {
                let key = fields[j].key;

                rowAsHash[key] = row[j];
              }
            }

            items.push(rowAsHash);
          }
        }

        return items;
      },

      download: function(fileType) {
        this.errors = [];
        this.reportLoading = true;

        let config = {
          pathVars: {
            reportId: this.reportId,
            fileType: fileType
          },
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'Accept': fileType === 'pdf' ? 'application/pdf' : 'text/csv'
          },
        };

        this.submit('post', ApiProperties.api.reportFile, config, this.postParams, response => {
          this.reportLoading = false;
          const fileName = this.report.name + '.' + fileType;
          this.forceBrowserDownloadWithNoLink(response.data, fileName);
        }, this.handleError);
      }
    },

    watch: {
      datePicker: {
        handler: function(change) {
          this.input.startDate = this.formatToIsoDate(change.startDate);
          this.input.endDate = this.formatToIsoDate(change.endDate);
        },
        deep: true
      }
    },

    created: function() {
      this.getCustomReport();
    },
  }
</script>

<style lang="scss">
  @import '../scss/app.scss';

  .reports {
    .report-name {
      h3 {
        font-size: 1.2rem;

        i {
          font-size: 3rem;
        }

        span {
          bottom: 15px;
        }
      }
    }

    thead {
      th {
        white-space: nowrap;
      }
    }

    .card {
      border-top: none;
      border-left: none;
      border-right: none;

      .card-header {
        background-color: white;
        border:0;
        padding-left: 0;

        .card-header-tabs {
          margin-left:0;
        }
      }

      .custom-go-button {
        color: #b2b6bc;
        font-weight: 700;
        font-size: .9rem;

      }

      .card-body {
        margin:0;
        padding:0;
      }

      li.nav-item {
        .nav-link {
          font-size: .9rem;
          line-height: normal;
          font-weight: bold;
          margin: 7px;
          background-color: #e4f5fd;
          border: 2px solid #20b6ff;
          border-radius: 10px 10px 10px 10px;
          color: #20b6ff;
          padding: .5rem;

          &:first-child {
            margin-left: 0;
          }

          &.active {
            color: #3c4858;
            margin-bottom: -4px;
            padding-bottom:19px;
            background-color: #fff;
            border: 2px solid #ccc;
            border-bottom: 0;
            border-radius: 10px 10px 0 0;
          }
        }

        &.custom-tab {
          .nav-link {
            .custom-tab-title { display: block; }
            .custom-inline-form { display:none; }

            &.active {
              padding-top: 6px;
              padding-bottom: 10px;
              .custom-tab-title { display: none; }
              .custom-inline-form { display:block; }
            }
          }
        }
      }

      .tab-content {
        border:2px solid #ccc;
        @extend .rounded;

        .download-links {
          top: -45px;

          img {
            margin-top: -3px;
          }

          a {
            font-size: .9rem;

            &:first-child {
              margin-right:15px;
            }
          }
        }
      }

      .report-results {
        p {
          font-size: .9rem;
        }
      }

      /* Inline HTML5 Date control mechanisms */
      .inline-date-input {
        border:none !important;
        padding: 0;
        font-size: .8rem;
        height:15px;
      }

      .form-control:focus{
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      .inline-date-input::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        display: none;
      }

      .inline-date-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
      }

      .inline-date-input::-webkit-clear-button {
        display: none;
        -webkit-appearance: none;
      }

      .li-inline-date-input {
        width:82px;
      }

    }
  }
</style>