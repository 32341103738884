export default {
  methods: {
    forceBrowserDownloadWithNoLink: function (data, fileName) {
      if (this.$browserDetect.isIE) {
        const fileBlobWithFileNameForMicrosoft = new Blob([data], fileName);
        window.navigator.msSaveOrOpenBlob(fileBlobWithFileNameForMicrosoft);
        window.URL.revokeObjectURL();
      } else {
        const fileBlob = new Blob([data]);
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement('a');

        // create and click to emulate file download in browser
        document.body.appendChild(link);
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();

        // destroy the link
        link.remove();
      }
    }
  }
}
